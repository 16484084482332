import { defineStore } from 'pinia';
import { useMutation } from '@tanstack/vue-query';
import { SavePersonalDetails } from '@tsamm-v2/sa-help/composables/types/PersonalDetails';
import { downLoadBlob, globalFetchOptions } from '~/composables/utilities/helpers';
import { KeyValueObject } from '../types/Common';

export const useApplicationStore = defineStore(
  'application',
  () => {
    const linkRedirect = ref('');
    const setLinkRedirect = (url: string) => {
      linkRedirect.value = url;
    };
    const getChatHistory = (id: string, page = 1, pageSize = 1000) => {
      return $fetch<{ success: boolean; data: unknown }>(
        `/applications/${id}/chat-history?sort=createdAt&order=asc&page=${page}&pageSize=${pageSize}`,
        {
          ...globalFetchOptions(),
          method: 'get',
        }
      );
    };

    const addChatText = (id: string) => {
      return useMutation((formData: { message: string }) =>
        $fetch<SavePersonalDetails>(`/applications/${id}/chat/text`, {
          ...globalFetchOptions(),
          method: 'POST',
          body: formData,
          retry: false,
        })
      );
    };

    const addChatFile = (id: string) => {
      return useMutation((formData: FormData) =>
        $fetch<SavePersonalDetails>(`/applications/${id}/chat/file`, {
          ...globalFetchOptions(),
          method: 'POST',
          body: formData,
          retry: false,
          onResponseError: (error: any) => {
            toastError('', 'There is an issue with the file you are trying to upload. Please confirm your file is valid and retry your upload.');
          }
        })
      );
    };

    const downloadChatFile = async (arId: string, fileId: string, name: string) => {
      const response = await $fetch<Blob>(`/applications/${arId}/file/${fileId}?fileName=${name}`, {
        ...globalFetchOptions(),
        method: 'get',
      });
      downLoadBlob(response, name);

      return '';
    };

    const autoCorrect = (keyValue: KeyValueObject) => {
      return $fetch<{ success: boolean; data: KeyValueObject }>(`/applications/autocorrect`, {
        ...globalFetchOptions(),
        method: 'POST',
        body: keyValue,
        retry: false,
      });
    };

    const getFileSrc = async (arId: string, fileId: string, name: string) => {
      return $fetch<Blob>(`/applications/${arId}/file/${fileId}?fileName=${name}`, {
        ...globalFetchOptions(),
        method: 'get',
      });
    };

    return {
      getChatHistory,
      addChatText,
      addChatFile,
      downloadChatFile,
      linkRedirect,
      setLinkRedirect,
      autoCorrect,
      getFileSrc,
    };
  },
  {
    persist: true,
  }
);
